.sign-up-image-container {
  background-image: url("");
  background-size: cover;
  background-position: center;
  min-height: 100%;
}

button,
a {
  transition: all 0.3s ease;
}

.MuiTextField-root,
.MuiSelect-root {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.MuiTextField-root:focus-within,
.MuiSelect-root:focus-within {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.MuiBox-root [type="file"] + label:hover {
  background-color: rgba(25, 118, 210, 0.08);
}

.MuiButton-containedPrimary:not(:disabled):hover {
  transform: translateY(-2px);
}

.MuiButton-containedPrimary:not(:disabled):active {
  transform: translateY(0);
}

@media (max-width: 600px) {
  .MuiContainer-root {
    padding: 16px 8px;
  }
}

